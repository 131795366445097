import axiosInstance from '@crema/helpers/axiosInstance';

export const listUsers = async () => {
  let result = await axiosInstance.get('api/accounts/user/list/');
  return result.data;
};

export const addUsers = async (data) => {
  let result = await axiosInstance.post('api/accounts/user/create/', data);
  return result.data;
};

export const listRole = async () => {
  let result = await axiosInstance.get('api/accounts/user/role/');
  return result.data;
};
