import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TextField,
  //FormControlLabel,
  MenuItem,
  Grid,
  InputAdornment,
} from '@mui/material';
//import Checkbox from '@mui/material/Checkbox';
import {useMutation} from '@tanstack/react-query';
import {addUsers, listRole} from '@crema/services/axios/accounts';
import Swal from 'sweetalert2';
import {useQuery} from '@tanstack/react-query';
import {MdAccountCircle} from 'react-icons/md';

const AddUsers = (props) => {
  const {handleCloseModal, isOpen} = props;

  const initialData = {
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    role_id: 0,
  };

  const [data, setData] = useState(initialData);
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);

  const generatedPassword = (length = 12) => {
    const charset =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
    const passwordArray = Array.from(
      crypto.getRandomValues(new Uint32Array(length)),
    );
    const generatedPassword = passwordArray
      .map((x) => charset[x % charset.length])
      .join('');
    setPassword(generatedPassword);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const {data: dataRoles = [], isLoading} = useQuery({
    queryKey: ['roles'],
    queryFn: listRole,
    enabled: !!isOpen,
  });

  const handleChange = (e) => {
    e.preventDefault();
    const {name, value} = e.target;

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'email') {
      setEmailError(!validateEmail(value));
    }
  };

  const mutate = useMutation({
    mutationFn: addUsers,
    onSuccess: () => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Usuario agregado correctamente',
        showConfirmButton: true,
      });
    },
    onError: () => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal',
      });
    },
  });

  console.log('aaaaa');
  console.log({props});
  console.table(data);

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.handleCloseModal}>
        <DialogTitle marginTop={5} fontSize={25} fontFamily={'Quicksand'}>
          Nuevo usuario
        </DialogTitle>
        <Grid
          display={'flex'}
          flexDirection={'row'}
          padding='20px 30px 0px 30px'
        >
          <DialogContentText>Nombre:</DialogContentText>
          <TextField
            id='input-with-icon-textfield'
            name='first_name'
            onChange={handleChange}
            style={{marginLeft: 40}}
            variant='standard'
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <MdAccountCircle size={'medium'} />
                </InputAdornment>
              ),
            }}
          ></TextField>
          <TextField
            label='Apellido'
            name='last_name'
            onChange={handleChange}
            style={{marginLeft: 15}}
          ></TextField>
        </Grid>
        <Grid
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          padding='20px 30px 0px 30px'
        >
          <DialogContentText>Correo:</DialogContentText>
          <TextField
            style={{width: 400, paddingLeft: 10}}
            name='email'
            onChange={handleChange}
            error={emailError}
            helperText={
              emailError ? 'Ingrese un correo electrónico válido.' : ''
            }
          ></TextField>
        </Grid>
        <Grid
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          padding='20px 30px 0px 30px'
        >
          <DialogContentText>Nombre de usuario:</DialogContentText>
          <TextField
            style={{width: 350}}
            name='username'
            onChange={handleChange}
          ></TextField>
        </Grid>
        <Grid
          padding='20px 30px 0px 30px'
          height={120}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <DialogContentText>Contraseña:</DialogContentText>
          <Grid
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
          >
            <Button
              onClick={() => {
                generatedPassword();
                console.log('contra' + password);
              }}
              variant='outlined'
            >
              Generar contraseña
            </Button>
            <TextField
              style={{width: 300}}
              label='contraseña'
              name='contraseña'
              value={password}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          padding='20px 30px 0px 30px'
        >
          {isLoading && '...cargando'}
          <DialogContentText>Rol:</DialogContentText>
          <TextField
            select
            name='role_id'
            onChange={handleChange}
            style={{width: 350}}
          >
            {dataRoles?.results?.map((item) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* <FormControlLabel
          control={<Checkbox defaultChecked />}
          label='Activo'
        /> */}
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseModal();
              setData(initialData);
              setPassword('');
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const dataPassword = {
                ...data,
                password,
              };
              mutate.mutate(dataPassword);
              handleCloseModal();
              setData(initialData);
            }}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AddUsers;
