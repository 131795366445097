import React, {useState} from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Grid,
  Button,
} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {addUsers, listUsers} from '@crema/services/axios/accounts';
import {FaCheckCircle} from 'react-icons/fa';
import {MdCancel} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import AddUsers from './AddUsers';

const AdminUser = () => {
  const [isOpen, setIsOpen] = useState(false);

  const {data: dataUsers = [], isLoading} = useQuery({
    queryKey: ['users'],
    queryFn: listUsers,
  });

  const {data: dataAdd} = useQuery({
    queryKey: ['add_users'],
    queryFn: addUsers,
  });

  console.log('users  ' + dataUsers);

  const navigate = useNavigate();

  const columns = [
    {id: 'name', label: 'Nombre'},
    {id: 'status', label: 'Activo'},
  ];

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Container>
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography
            style={{fontFamily: 'Quicksand', fontWeight: 400, fontSize: 40}}
          >
            Usuarios
          </Typography>
          <Button
            style={{height: 45}}
            variant='contained'
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Nuevo usuario
          </Button>
        </Grid>
        {isLoading && '...cargando'}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataUsers.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Grid display={'flex'}>
                      <ListItemAvatar>
                        <Avatar
                          style={{backgroundColor: '#7E5920'}}
                          alt='Remy Sharp'
                        />
                      </ListItemAvatar>
                      <ListItemButton
                        value={item?.value}
                        style={{fontSize: 18, fontFamily: 'Quicksand'}}
                        onClick={() => {
                          navigate('/users');
                        }}
                      >
                        {item.label}
                      </ListItemButton>
                    </Grid>
                    <Grid>
                      <ListItemText style={{marginLeft: 45}}>
                        {item.role != 'None' ? `Role: ${item.role}` : ''}
                      </ListItemText>
                      <ListItemText style={{marginLeft: 45}}>
                        Username: {item.username}
                      </ListItemText>
                      <ListItemText style={{marginLeft: 45}}>
                        Email: {item.email}
                      </ListItemText>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {item.is_active ? (
                      <FaCheckCircle color='green' />
                    ) : (
                      <MdCancel color='#800E13' />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <AddUsers isOpen={isOpen} handleCloseModal={handleCloseModal} />
      </Container>
    </>
  );
};
export default AdminUser;
