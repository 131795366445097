import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import Account from '../../../modules/account/MyProfile';
import AdminUser from 'modules/userPages/AdminUsers/AdminUsers';
import EditUsers from 'modules/userPages/AdminUsers/AddUsers';

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/my-profile',
    element: <Account />,
  },
  {
    path: '/admin-users',
    element: <AdminUser />,
  },
  {
    path: '/edit-users',
    element: <EditUsers />,
  },
];
